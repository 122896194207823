import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BottomDrawerComponent } from './bottom-drawer/bottom-drawer.component';

@NgModule({
    declarations: [BottomDrawerComponent],
    imports: [CommonModule, FormsModule, IonicModule],
    exports: [BottomDrawerComponent],
})
export class ComponentModule {}
