import { Component, OnInit, AfterViewInit, HostListener, ViewChild } from '@angular/core';
import { AppContextService } from 'src/app/services/app-context.service';
import { Router } from '@angular/router';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';

@Component({
    selector: 'bottom-drawer',
    templateUrl: './bottom-drawer.component.html',
    styleUrls: ['./bottom-drawer.component.scss'],
})
export class BottomDrawerComponent implements OnInit, AfterViewInit {
    bottomDrawer: CupertinoPane;
    drawerOptions: CupertinoSettings;
    labelStammdaten = 'Erfassung der persönlichen Daten';
    labelBuergschaftsvertrag = 'Erfassung der Daten';
    labelVermittlungsvertrag = 'Erfassung der Daten';
    labelWerbeeinwilligung = 'Erfassung der Daten';
    labelDiskontcourtageantrag = 'Erfassung der Daten';
    labelDatenschutzbeauftragter = 'Erfassung der Daten';
    labelIdentifizierungsnachweis_natpersonen = 'Erfassung der Daten';
    labelIdentifizierungsnachweis_jurpersonen = 'Erfassung der Daten';
    labelErweiterungb196 = 'Erweiterung der Fahrerlaubnis auf Klasse B 196';
    labelDynamicform = 'Dynamische Formular';
    labelStandbuchung = 'Standbuchung Forum Süd-West 2023';
    labelBankdaten = 'Erfassung der Bankverbindung.';
    labelUmfang = 'Festlegung des gewünschten Betreuungsumfangs.';
    labelKontakt = 'Festlegung der Kontaktwege.';
    labelBestandsaufnahme = 'Erfassung der aktuellen Absicherungssituation.';
    labelDatenschutz = 'Festlegung Kommunikation und Datenschutz';
    labelZusammenfasung = 'Zusammenfassung der Eingaben.';
    labelEinwilligung = 'Erteilung der Einwilligung.';
    labelEinwilligungErstinfo = 'Erteilung der Einwilligung zur Erstinfo.';
    labelUnterschrift = 'Unterschrift und Abschluß.';
    labelDokumentabruf = 'Abruf der Dokumentenmappe.';
    labelFileUpload = 'Laden Sie Ihre Dokumente hoch.';
    styling: any;

    constructor(public appContext: AppContextService, public router: Router) {}

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.bottomDrawer.destroy();
        this.ngAfterViewInit();
    }

    ngOnInit() {
        this.styling = this.appContext.context.customizing.styling;
        this.drawerOptions = {
            breaks: {
                top: {
                    enabled: true,
                    height: this.appContext.context.isPlatformDesktop ? 285 : 650,
                },
                middle: {
                    enabled: false,
                },
                bottom: {
                    enabled: true,
                    //height: 54,
                    height: this.appContext.context.isPlatformDesktop ? 54 : 64,
                },
            },
            initialBreak: 'bottom',
            buttonClose: false,
            animationDuration: 400,
            clickBottomOpen: false,
        };

        this.changeScreenSize();
    }

    changeScreenSize() {
        window.resizeTo(screen.width - 200, screen.height - 500);
    }

    ngAfterViewInit() {
        this.bottomDrawer = new CupertinoPane('.bottom-drawer', this.drawerOptions);

        if (document.URL.indexOf('signature') === -1) {
            this.bottomDrawer.present();
            document.querySelector('.draggable').addEventListener('click', () => {
                if (this.bottomDrawer.currentBreak() === 'top') {
                    this.bottomDrawer.moveToBreak('bottom');
                } else {
                    this.bottomDrawer.moveToBreak('top');
                }
            });
            document.querySelector('.bulltes-container').addEventListener('click', () => {
                if (this.bottomDrawer.currentBreak() === 'top') {
                    this.bottomDrawer.moveToBreak('bottom');
                } else {
                    this.bottomDrawer.moveToBreak('top');
                }
            });
            if (this.appContext.context.isPlatformDesktop) document.querySelector('.pane').classList.add('desktop');
            else document.querySelector('.pane').classList.add('mobile');
        }
    }

    navigateToPage(pageName: string) {
        switch (pageName) {
            case 'stammdaten':
                if (this.appContext.context.isLoggedIn && this.appContext.context.isStammdatenVisited)
                    this.router.navigateByUrl('stammdaten');
                break;
            case 'vermittlungsvertrag':
                if (this.appContext.context.isLoggedIn && this.appContext.context.isVermittlungsvertragVisited) {
                    this.router.navigateByUrl('vermittlungsvertrag');
                }
                break;
            case 'datenschutzbeauftragter':
                if (this.appContext.context.isLoggedIn && this.appContext.context.isDatenschutzbeauftragterVisited)
                    this.router.navigateByUrl('datenschutzbeauftragter');
                break;
            case 'identifizierungsnachweis_natpersonen':
                if (
                    this.appContext.context.isLoggedIn &&
                    this.appContext.context.isIdentifizierungsnachweis_natpersonenVisited
                )
                    this.router.navigateByUrl('identifizierungsnachweis_natpersonen');
                break;
            case 'identifizierungsnachweis_jurpersonen':
                if (
                    this.appContext.context.isLoggedIn &&
                    this.appContext.context.isIdentifizierungsnachweis_jurpersonenVisited
                )
                    this.router.navigateByUrl('identifizierungsnachweis_jurpersonen');
                break;
            case 'diskontcourtageantrag':
                if (this.appContext.context.isLoggedIn && this.appContext.context.isDiskontcourtageantragVisited)
                    this.router.navigateByUrl('diskontcourtageantrag');
                break;
            case 'buergschaftsvertrag':
                if (this.appContext.context.isLoggedIn && this.appContext.context.isBuergschaftsvertragVisited)
                    this.router.navigateByUrl('buergschaftsvertrag');
                break;
            case 'werbeeinwilligung':
                if (this.appContext.context.isLoggedIn && this.appContext.context.isWerbeeinwilligungVisited)
                    this.router.navigateByUrl('werbeeinwilligung');
                break;
            case 'erweiterungb196':
                if (this.appContext.context.isLoggedIn && this.appContext.context.isErweiterungb196Visited)
                    this.router.navigateByUrl('erweiterungb196');
                break;
            case 'dynamicform':
                if (this.appContext.context.isLoggedIn && this.appContext.context.isDynamicformVisited)
                    this.router.navigateByUrl('dynamicform');
                break;
            case 'dkm':
                if (this.appContext.context.isLoggedIn && this.appContext.context.isdkmVisited)
                    this.router.navigateByUrl('dkm');
                break;
            case 'standbuchung':
                if (this.appContext.context.isLoggedIn && this.appContext.context.isstandbuchungVisited)
                    this.router.navigateByUrl('standbuchung');
                break;
            case 'bankdaten':
                if (this.appContext.context.isLoggedIn && this.appContext.context.isBankdatenVisited)
                    this.router.navigateByUrl('bankdaten');
                break;
            case 'umfang':
                if (this.appContext.context.isUmfangVisited) this.router.navigateByUrl('umfang');
                break;
            case 'bestandsaufnahme':
                if (this.appContext.context.isBestandsaufnahmeVisited) this.router.navigateByUrl('bestandsaufnahme');
                break;
            case 'kontakt':
                if (this.appContext.context.isKontaktVisited) this.router.navigateByUrl('kontakt');
                break;
            case 'datenschutz':
                if (this.appContext.context.isDatenschutzVisited) this.router.navigateByUrl('datenschutz');
                break;
            case 'frei':
                if (this.appContext.context.isDatenschutzVisited) this.router.navigateByUrl('frei');
                break;
            case 'einwilligungerstinfo':
                if (this.appContext.context.isEinwilligungErstinfoVisited)
                    this.router.navigateByUrl('einwilligungerstinfo');
                break;
            case 'zusammenfassung':
                if (this.appContext.context.isZusammenfassungVisited) this.router.navigateByUrl('zusammenfassung');
                break;
            case 'fileupload':
                if (this.appContext.context.isFileUploadVisited) this.router.navigateByUrl('fileupload');
                break;
            case 'einwilligung':
                if (this.appContext.context.isEinwilligungVisited) this.router.navigateByUrl('einwilligung');
                break;
            case 'unterschrift':
                if (this.appContext.context.isUnterschriftVisited) this.router.navigateByUrl('unterschrift');
                break;
        }

        this.bottomDrawer.moveToBreak('bottom');
    }
}
