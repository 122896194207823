import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'start',
        pathMatch: 'full',
    },
    {
        path: 'start',
        loadChildren: () => import('./pages/start/start.module').then((m) => m.StartPageModule),
    },
    {
        path: 'stammdaten',
        loadChildren: () => import('./pages/stammdaten/stammdaten.module').then((m) => m.StammdatenPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'buergschaftsvertrag',
        loadChildren: () =>
            import('./pages/buergschaftsvertrag/buergschaftsvertrag.module').then(
                (m) => m.BuergschaftsvertragPageModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: 'vermittlungsvertrag',
        loadChildren: () =>
            import('./pages/vermittlungsvertrag/vermittlungsvertrag.module').then(
                (m) => m.VermittlungsvertragPageModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: 'werbeeinwilligung',
        loadChildren: () =>
            import('./pages/werbeeinwilligung/werbeeinwilligung.module').then((m) => m.WerbeeinwilligungPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'diskontcourtageantrag',
        loadChildren: () =>
            import('./pages/diskontcourtageantrag/diskontcourtageantrag.module').then(
                (m) => m.DiskontcourtageantragPageModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: 'datenschutzbeauftragter',
        loadChildren: () =>
            import('./pages/datenschutzbeauftragter/datenschutzbeauftragter.module').then(
                (m) => m.DatenschutzbeauftragterPageModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: 'identifizierungsnachweis_natpersonen',
        loadChildren: () =>
            import('./pages/identifizierungsnachweis_natpersonen/identifizierungsnachweis_natpersonen.module').then(
                (m) => m.Identifizierungsnachweis_natpersonenPageModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: 'identifizierungsnachweis_jurpersonen',
        loadChildren: () =>
            import('./pages/identifizierungsnachweis_jurpersonen/identifizierungsnachweis_jurpersonen.module').then(
                (m) => m.Identifizierungsnachweis_jurpersonenPageModule
            ),
        canActivate: [AuthGuardService],
    },

    {
        path: 'erweiterungb196',
        loadChildren: () =>
            import('./pages/erweiterungb196/erweiterungb196.module').then((m) => m.Erweiterungb196PageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'dynamicform',
        loadChildren: () => import('./pages/dynamicform/dynamicform.module').then((m) => m.dynamicformPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'dkm',
        loadChildren: () => import('./pages/dkm/dkm.module').then((m) => m.dkmPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'standbuchung',
        loadChildren: () => import('./pages/standbuchung/standbuchung.module').then((m) => m.standbuchungPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'bankdaten',
        loadChildren: () => import('./pages/bankdaten/bankdaten.module').then((m) => m.BankdatenPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'umfang',
        loadChildren: () => import('./pages/umfang/umfang.module').then((m) => m.UmfangPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'kontakt',
        loadChildren: () => import('./pages/kontakt/kontakt.module').then((m) => m.KontaktPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'bestandsaufnahme',
        loadChildren: () =>
            import('./pages/bestandsaufnahme/bestandsaufnahme.module').then((m) => m.BestandsaufnahmePageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'datenschutz',
        loadChildren: () => import('./pages/datenschutz/datenschutz.module').then((m) => m.DatenschutzPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'frei',
        loadChildren: () => import('./pages/frei/frei.module').then((m) => m.FreiPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'zusammenfassung',
        loadChildren: () =>
            import('./pages/zusammenfassung/zusammenfassung.module').then((m) => m.ZusammenfassungPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'idupload',
        loadChildren: () => import('./pages/idupload/idupload.module').then((m) => m.iduploadPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'fileupload',
        loadChildren: () => import('./pages/fileupload/fileupload.module').then((m) => m.FileUploadPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'einwilligungerstinfo',
        loadChildren: () =>
            import('./pages/einwilligungerstinfo/einwilligungerstinfo.module').then(
                (m) => m.EinwilligungErstinfoPageModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: 'einwilligung',
        loadChildren: () => import('./pages/einwilligung/einwilligung.module').then((m) => m.EinwilligungPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'unterschrift',
        loadChildren: () => import('./pages/unterschrift/unterschrift.module').then((m) => m.UnterschriftPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'datenschutzerklaerung',
        loadChildren: () =>
            import('./pages/datenschutzerklaerung/datenschutzerklaerung.module').then(
                (m) => m.DatenschutzerklaerungPageModule
            ),
    },
    {
        path: 'impressum',
        loadChildren: () => import('./pages/impressum/impressum.module').then((m) => m.ImpressumPageModule),
    },
    {
        path: 'import',
        loadChildren: () => import('./pages/import/import.module').then((m) => m.ImportPageModule),
    },
    {
        path: '**',
        redirectTo: 'start',
    },
    {
        path: 'frei',
        loadChildren: () => import('./pages/frei/frei.module').then((m) => m.FreiPageModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
