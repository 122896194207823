import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AppContextService } from './app-context.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    constructor(public router: Router, public appContext: AppContextService) {}

    canActivate(): boolean {
        if (!this.appContext.context.isLoggedIn) {
            this.router.navigate(['start']);
            return false;
        }
        return true;
    }
}
